<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-app-bar
        flat
        v-if="can('edit-notification') && originalData.status_id === 1"
      >
        <rs-action
          v-if="$refs.confirmSend"
          @click="
            $refs.confirmSend.show('Toplu bildirim gönderilecek. Emin misiniz?')
          "
          :loading="loading"
        >
          Gönder
        </rs-action>

        <rs-action
          @click="$refs.sendTestForm.show(id)"
          :loading="loading"
          v-if="originalData.id && $refs.sendTestForm"
        >
          Test Gönder
        </rs-action>

        <rs-action
          @click="
            $refs.confirmDelete.show('Toplu bildirim silinecek. Emin misiniz?')
          "
          :loading="loading"
          v-if="
            originalData.id && $refs.confirmDelete && can('delete-notification')
          "
        >
          Sil
        </rs-action>

        <rs-confirm
          ref="confirmSend"
          :loading="isLoading"
          @confirmed="handleSendConfirmed"
          @cancelled="$refs.confirmSend.hide()"
        />

        <rs-confirm
          ref="confirmDelete"
          :loading="isLoading"
          @confirmed="handleDeleteConfirmed"
          @cancelled="$refs.confirmDelete.hide()"
        />
      </v-app-bar>

      <v-card-title>Toplu Bildirim</v-card-title>

      <v-card-text>
        <v-form
          :disabled="disabled || (id && originalData.status_id !== 1)"
          @submit.prevent="handleFormSubmit"
          ref="form"
        >
          <v-row dense cols="12">
            <v-col sm="6">
              <rs-text-field
                label="Gönderi Adı"
                v-model="formData.name"
                :rules="[rules.required, rules.maxLength(formData.name, 50)]"
                required
                hint="Sakinler göremez"
              />
            </v-col>

            <v-col sm="6">
              <v-checkbox
                v-model="formData.for_each_house_user"
                label="Her BB için ayrı bildirim gönder"
                hint="Bağımsız bölümlere ait bilgi göndereceksiniz işaretleyiniz"
                persistent-hint
                :disabled="id"
              />

              <p class="red--text" v-if="!formData.for_each_house_user">
                Her sakin için tek bir bildirim gönderilecek.
              </p>
            </v-col>

            <v-col sm="6">
              <rs-select
                label="Bildirim Türü"
                v-model="formData.notification_channel_id"
                :items="notificationChannelListIds"
                :rules="[rules.required]"
                @change:item="handleChannelUpdate"
              />
            </v-col>

            <v-col sm="6">
              <rs-select-notification-template
                v-model="formData.template_id"
                @change:item="handleTemplateUpdate"
                sendable
              />
            </v-col>
          </v-row>

          <v-row dense cols="12" v-if="!formData.multiple_body">
            <v-col sm="8">
              <rs-textarea
                label="Mesaj"
                :rules="[rules.required, rules.maxLength(formData.body, 500)]"
                v-model="formData.body"
                rows="5"
                required
                ref="body"
              />

              <div class="elevation-2 p-2 mt-2" v-if="example">
                <strong>Örnek Mesaj:</strong>
                <pre>{{ example }}</pre>
              </div>
            </v-col>

            <v-col sm="4" v-if="!id || originalData.status_id === 1">
              <VariableCloud
                :system-id="
                  selectedTemplate ? selectedTemplate.system_id : null
                "
                @select="addVariable"
              />
            </v-col>
          </v-row>

          <v-tabs
            v-model="selectedTab"
            v-if="formData.multiple_body"
            class="mt-5"
            @change="handleTabChange"
          >
            <v-tab href="#sms" v-if="formData.sms_available"> SMS </v-tab>
            <v-tab href="#email" v-if="formData.email_available">
              E-posta
            </v-tab>
            <v-tab href="#mobile-push" v-if="formData.mobile_push_available">
              Mobil Bildirim
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="selectedTab" v-if="formData.multiple_body">
            <v-tab-item class="py-4" value="sms">
              <v-row dense cols="12">
                <v-col sm="8" md="7">
                  <rs-textarea
                    label="SMS Mesajı"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.body_sms, 280),
                    ]"
                    v-model="formData.body_sms"
                    rows="5"
                    required
                    ref="body_sms"
                  />

                  <div class="elevation-2 p-2 mt-2" v-if="example">
                    <strong>Örnek Mesaj:</strong>
                    <pre>{{ example }}</pre>
                  </div>
                </v-col>

                <v-col sm="4" md="5">
                  <v-btn
                    @mousedown="addVariable(item)"
                    v-for="item in variables"
                    :key="item.value"
                    depressed
                    color="pink"
                    outlined
                    small
                    class="me-2 mb-2 px-1"
                  >
                    {{ item.label }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="py-4" value="email">
              <v-row dense cols="12">
                <v-col sm="8" md="7">
                  <rs-textarea
                    label="E-posta Mesajı"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.body_email, 2000),
                    ]"
                    v-model="formData.body_email"
                    rows="5"
                    required
                    ref="body_email"
                  />

                  <div class="elevation-2 p-2 mt-2" v-if="example">
                    <strong>Örnek Mesaj:</strong>
                    <pre>{{ example }}</pre>
                  </div>
                </v-col>

                <v-col sm="4" md="5">
                  <v-btn
                    @mousedown="addVariable(item)"
                    v-for="item in variables"
                    :key="item.value"
                    depressed
                    color="pink"
                    outlined
                    small
                    class="me-2 mb-2 px-1"
                  >
                    {{ item.label }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="py-4" value="mobile-push">
              <v-row dense cols="12">
                <v-col sm="8" md="7">
                  <rs-textarea
                    label="Mobil Bildirim Mesajı"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.body_mobile_push, 100),
                    ]"
                    v-model="formData.body_mobile_push"
                    rows="5"
                    required
                    ref="body_mobile_push"
                  />

                  <div class="elevation-2 p-2 mt-2" v-if="example">
                    <strong>Örnek Mesaj:</strong>
                    <pre>{{ example }}</pre>
                  </div>
                </v-col>

                <v-col sm="4" md="5">
                  <v-btn
                    @mousedown="addVariable(item)"
                    v-for="item in variables"
                    :key="item.value"
                    depressed
                    color="pink"
                    outlined
                    small
                    class="me-2 mb-2 px-1"
                  >
                    {{ item.label }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <rs-form-buttons
            v-if="!disabled"
            :is-loading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            :hide-submit="disabled || (id && originalData.status_id !== 1)"
            :hide-save-close="disabled || (id && originalData.status_id !== 1)"
          />
        </v-form>
      </v-card-text>

      <SendTestForm ref="sendTestForm" />
    </v-card>

    <v-card
      class="mb-2"
      v-if="originalData.id && originalData.notification_channel_id === 4"
      :loading="isLoading"
    >
      <v-card-title v-if="!id || originalData.status_id === 1">
        E-posta Ekleri
      </v-card-title>
      <v-card-text>
        <v-chip
          class="ma-2"
          close
          color="pink"
          label
          text-color="white"
          v-for="item in originalData.attachments"
          :key="item.id"
          @click:close="handleAttachmentDeleteClick(item)"
        >
          <v-icon left>mdi-file</v-icon>
          {{ item.document.title }}.{{ item.document.extension }}
        </v-chip>
        <rs-action
          v-if="$refs.documentForm"
          @click="$refs.documentForm.show()"
          :loading="loading"
        >
          Belge Ekle
        </rs-action>

        <DocumentForm
          :extra-params="{ cluster_id: clusterId }"
          @dismissed="handleFormDismiss"
          @saved="handleFormSaved"
          ref="documentForm"
        />
      </v-card-text>
    </v-card>

    <v-card v-if="originalData.id" :loading="isLoading">
      <v-card-title v-if="!id || originalData.status_id === 1">
        Gönderilecek Kişiler
      </v-card-title>
      <v-card-title v-else>Kişiler</v-card-title>
      <v-card-text>
        <v-data-table
          :fixed-header="false"
          :height="undefined"
          v-bind="dataTableAttrs"
          :headers="headersShown"
          :items="recipientList"
          :loading="isLoading"
          :options.sync="options"
          :server-items-length="total"
          :items-per-page.sync="itemsPerPage"
          v-model="selectedItems"
        >
          <template v-slot:top v-if="can('edit-notification')">
            <v-row dense v-if="originalData.status_id === 1">
              <v-col>
                <v-btn
                  class="me-1 mb-1"
                  color="purple"
                  outlined
                  small
                  @click="handleClickFilterSelect"
                >
                  Filtreleyerek Ekle
                </v-btn>

                <v-btn
                  class="me-1 mb-1"
                  color="pink"
                  outlined
                  small
                  @click="handleClickCustomSelect"
                >
                  Seçerek Ekle
                </v-btn>

                <v-btn
                  class="me-1 mb-1"
                  color="pink"
                  outlined
                  small
                  @click="handleClickAll"
                >
                  Tüm Sakinleri Ekle
                </v-btn>

                <v-btn
                  class="me-1 mb-1"
                  color="pink"
                  outlined
                  small
                  @click="handleClickOwners"
                >
                  Ev Sahiplerini Ekle
                </v-btn>

                <v-btn
                  class="me-1 mb-1"
                  color="pink"
                  outlined
                  small
                  @click="handleClickRenters"
                >
                  Kiracıları Ekle
                </v-btn>

                <v-btn
                  class="me-1 mb-1"
                  color="pink"
                  outlined
                  small
                  @click="handleClickDebitors"
                >
                  Borçluları Ekle
                </v-btn>

                <v-btn
                  class="me-1 mb-1"
                  color="pink"
                  outlined
                  small
                  @click="handleClickDebitorOwners"
                >
                  Borçlu Ev Sahiplerini Ekle
                </v-btn>

                <v-btn
                  class="me-1 mb-1"
                  color="pink"
                  outlined
                  small
                  @click="handleClickDebitorRenters"
                >
                  Borçlu Kiracıları Ekle
                </v-btn>

                <v-btn
                  class="me-1 mb-1"
                  color="pink"
                  outlined
                  small
                  @click="handleClickEnforcementResidents"
                >
                  Açık İcra Dosyalarındaki Ekle
                </v-btn>
              </v-col>
            </v-row>
            <rs-table-title-bar
              v-if="
                $refs.headSearch &&
                $refs.confirmResidentDelete &&
                $refs.batchNotificationResidentForm
              "
              class="mt-6"
              title="Kişiler"
              icon=""
              :add-route="
                can('edit-notification') && originalData.status_id === 1
                  ? '#'
                  : null
              "
              @click:add.prevent="handleAddClick"
              @clear-filters="$refs.headSearch.reset"
              @reload="loadRecipientList"
              hide-edit
              :search.sync="search.query"
              :show-delete="
                can('edit-notification') && originalData.status_id === 1
              "
              :delete-enabled="selectedItems.length > 0"
              @click:delete="
                $refs.confirmResidentDelete.show(
                  'Seçili sakinleri silmek istediğinizden emin misiniz?'
                )
              "
              v-bind="titleBarAttrs"
            />

            <rs-confirm
              ref="confirmResidentDelete"
              :loading="isLoading"
              @confirmed="handleResidentDeleteSubmitClick"
              @cancelled="$refs.confirmResidentDelete.hide()"
            />

            <BatchNotificationResidentForm
              @saved="loadResidentList"
              ref="batchNotificationResidentForm"
            />

            <BatchNotificationHouseUserForm
              @saved="loadHouseUserList"
              ref="batchNotificationHouseUserForm"
            />

            <rs-modal-resident
              ref="modalResident"
              @selected="handleResidentSelected"
              size="large"
            />

            <rs-modal-house-user
              ref="modalHouseUser"
              @selected="handleHouseUserSelected"
              size="large"
            />

            <AddResidentsByFilterForm
              @saved="loadResidentList"
              @selected="handleResidentSelected"
              ref="addResidentsByFilterForm"
            />

            <AddHouseUsersByFilterForm
              @saved="loadHouseUserList"
              @selected="handleHouseUserSelected"
              ref="addHouseUsersByFilterForm"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:body.prepend="{ headers }">
            <rs-table-head-search
              :headers="headers"
              :search.sync="search"
              ref="headSearch"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.block_id="{ item }">
            {{ item.block }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.balance="{ value }">
            <rs-table-cell-balance :value="value" colored />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import {
  hasDataTable,
  hasExport,
  hasForm,
  hasPermissions,
} from "@/view/mixins";
import { notificationTemplateVariables } from "@/core/data/options";
import VariableCloud from "../VariableCloud";
import BatchNotificationHouseUserForm from "./BatchNotificationHouseUserForm";
import BatchNotificationResidentForm from "./BatchNotificationResidentForm";
import SendTestForm from "./SendTestForm";
import AddHouseUsersByFilterForm from "./AddHouseUsersByFilterForm.vue";
import AddResidentsByFilterForm from "./AddResidentsByFilterForm.vue";
import DocumentForm from "@/view/content/documents/DocumentForm";

export default {
  name: "BatchNotificationForm",
  mixins: [hasDataTable, hasExport, hasForm, hasPermissions],
  components: {
    AddHouseUsersByFilterForm,
    AddResidentsByFilterForm,
    BatchNotificationHouseUserForm,
    BatchNotificationResidentForm,
    DocumentForm,
    SendTestForm,
    VariableCloud,
  },
  computed: {
    ...mapGetters([
      "blocks",
      "clusterId",
      "companyId",
      "houseUserTypeList",
      "notificationChannelListIds",
      "notificationStatusList",
    ]),
  },
  watch: {
    id: {
      handler(newValue) {
        if (newValue) {
          this.load();
        }
      },
      immediate: true,
    },
    options: {
      handler() {
        this.loadRecipientList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadRecipientList();
      }),
      deep: true,
    },
  },
  mounted() {
    if (!this.clusterId && !this.id) {
      this.$toast.error("Bir TYA seçili olmalı.");
      this.$router.back();
    }

    this.generateExportColumns(this.headers);
  },
  data() {
    return {
      titleBarAttrs: {
        exportUrl: () => {
          if (this.originalData.for_each_house_user) {
            return `batch-notifications/${this.id}/house-users`;
          } else {
            return `batch-notifications/${this.id}/residents`;
          }
        },
        exportParams: this.getParams,
      },
      selectedTab: "sms",
      activeModel: "body",
      example: "",
      variables: notificationTemplateVariables,
      selectedTemplate: null,
      selectedChannel: null,
      formData: {
        name: null,
        notification_channel_id: 2,
        template_id: null,
        for_each_house_user: true,
        body: null,
        status_id: null,
        // body_sms: null,
        // body_email: null,
        // body_mobile_push: null,
      },
      recipientList: [],
      options: {
        sortBy: ["resident"],
        sortDesc: [false],
      },
      headers: [
        {
          text: "Sakin",
          value: "resident",
          searchable: "text",
        },
        {
          text: "Blok",
          value: "block_id",
          searchable: "multiselect",
          options: () => this.blocks,
        },
        {
          text: "BB",
          value: "door_number",
          searchable: "text",
        },
        {
          text: "Oturum Şekli",
          value: "house_user_type",
          searchable: "multiselect",
          searchValue: "house_user_type_id",
          options: () => this.houseUserTypeList,
          hide: () =>
            !this.originalData || !this.originalData.for_each_house_user,
        },
        {
          text: "Bakiye",
          value: "balance",
          searchable: "number",
          hide: () =>
            !this.originalData || !this.originalData.for_each_house_user,
        },
        {
          text: "Telefon",
          value: "phone",
          searchable: "text",
        },
        {
          text: "E-posta",
          value: "email",
          searchable: "text",
        },
        {
          text: "Durum",
          value: "status",
          searchable: "multiselect",
          searchValue: "status_id",
          options: () => this.notificationStatusList,
        },
      ],
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return params;
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      if (!this.id) {
        formData.cluster_id = this.clusterId;

        this.$api
          .post("batch-notifications", formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.$router.back();
            } else {
              this.isLoading = false;

              this.$router.replace({
                name: "communication.batch-notifications.edit",
                params: { id: response.data.data.id },
              });
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$api
          .put(`batch-notifications/${this.id}`, formData)
          .then(() => {
            this.$toast.success(this.$t("saved"));
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.$router.back();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("batch-notifications", this.id)
        .then((response) => {
          this.loadData(response);
          if (this.originalData.cluster_id) {
            this.updateClusterId(this.originalData.cluster_id);
          }

          if (this.originalData.company_id) {
            this.updateCompanyId(this.originalData.company_id);
          }

          this.selectedTemplate = response.data.data.template;

          setTimeout(() => this.loadRecipientList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    loadRecipientList() {
      if (!this.id || !this.originalData) {
        return false;
      }

      if (this.originalData.for_each_house_user) {
        this.loadHouseUserList();
      } else {
        this.loadResidentList();
      }
    },
    loadHouseUserList() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;
      this.resetTable();

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`batch-notifications/${this.id}/house-users`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.recipientList = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    loadResidentList() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;
      this.resetTable();

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`batch-notifications/${this.id}/residents`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.recipientList = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleTabChange(tabValue) {
      if (tabValue === "sms") {
        this.activeModel = "body_sms";
      } else if (tabValue === "email") {
        this.activeModel = "body_email";
      } else if (tabValue === "mobile-push") {
        this.activeModel = "body_mobile_push";
      } else {
        this.activeModel = "body";
      }

      this.updateExample(this.formData[this.activeModel]);
    },
    addVariable(variable) {
      this.formData[this.activeModel] = this.formData[this.activeModel] || "";

      if (
        this.$refs[this.activeModel] &&
        document.activeElement &&
        document.activeElement.tagName === "TEXTAREA"
      ) {
        const start = document.activeElement.value.substr(
          0,
          document.activeElement.selectionStart
        );
        const end = document.activeElement.value.substr(
          document.activeElement.selectionStart
        );

        this.formData[this.activeModel] = start + variable.value + end;
      } else {
        this.formData[this.activeModel] += variable.value;
      }
    },
    updateExample(model) {
      this.example = model;

      for (const index in this.variables) {
        this.example = this.example.replace(
          new RegExp(this.variables[index].value, "gi"),
          this.variables[index].example
        );
      }
    },
    handleDeleteConfirmed() {
      if (!this.id || this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`batch-notifications/${this.id}`)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));
          this.$router.replace({
            name: "communication.batch-notifications.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleTemplateUpdate(item) {
      if (!item) {
        this.selectedTemplate = null;
      } else {
        this.selectedTemplate = item;
        this.updateMessageFromTemplate();
      }
    },
    handleChannelUpdate(item) {
      if (!item) {
        this.selectedChannel = null;
      } else {
        this.selectedChannel = item;
        this.updateMessageFromTemplate();
      }
    },
    updateMessageFromTemplate() {
      if (this.formData.notification_channel_id && this.formData.template_id) {
        if (!this.selectedTemplate.multiple_body) {
          this.formData.body = this.selectedTemplate.body;
        } else if (this.formData.notification_channel_id === 2) {
          this.formData.body = this.selectedTemplate.body_sms;
        } else if (this.formData.notification_channel_id === 5) {
          this.formData.body = this.selectedTemplate.body_mobile_push;
        } else if (this.formData.notification_channel_id === 4) {
          this.formData.body = this.selectedTemplate.body_email;
        }
      }
    },
    handleResidentDeleteSubmitClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      const params = {
        ids: this.selectedItems.map((item) => item.id),
      };

      let url = `batch-notifications/${this.id}/house-users`;

      if (!this.originalData.for_each_house_user) {
        url = `batch-notifications/${this.id}/residents`;
      }

      this.$api
        .delete(url, params)
        .then(() => {
          this.$toast.success("Silindi");
          this.$refs.confirmResidentDelete.hide();
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleClickAll() {
      if (this.isLoading) {
        return false;
      }

      let url = `batch-notifications/${this.id}/house-users/all`;

      if (!this.originalData.for_each_house_user) {
        url = `batch-notifications/${this.id}/residents/all`;
      }

      this.isLoading = true;

      this.$api
        .post(url)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleClickOwners() {
      if (this.isLoading) {
        return false;
      }

      let url = `batch-notifications/${this.id}/house-users/owners`;

      if (!this.originalData.for_each_house_user) {
        url = `batch-notifications/${this.id}/residents/owners`;
      }

      this.isLoading = true;

      this.$api
        .post(url)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleClickRenters() {
      if (this.isLoading) {
        return false;
      }

      let url = `batch-notifications/${this.id}/house-users/renters`;

      if (!this.originalData.for_each_house_user) {
        url = `batch-notifications/${this.id}/residents/renters`;
      }

      this.isLoading = true;

      this.$api
        .post(url)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleClickDebitors() {
      if (this.isLoading) {
        return false;
      }

      let url = `batch-notifications/${this.id}/house-users/debitors`;

      if (!this.originalData.for_each_house_user) {
        url = `batch-notifications/${this.id}/residents/debitors`;
      }

      this.isLoading = true;

      this.$api
        .post(url)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleClickDebitorOwners() {
      if (this.isLoading) {
        return false;
      }

      let url = `batch-notifications/${this.id}/house-users/debitor-owners`;

      if (!this.originalData.for_each_house_user) {
        url = `batch-notifications/${this.id}/residents/debitor-owners`;
      }

      this.isLoading = true;

      this.$api
        .post(url)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleClickDebitorRenters() {
      if (this.isLoading) {
        return false;
      }

      let url = `batch-notifications/${this.id}/house-users/debitor-renters`;

      if (!this.originalData.for_each_house_user) {
        url = `batch-notifications/${this.id}/residents/debitor-renters`;
      }

      this.isLoading = true;

      this.$api
        .post(url)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleClickEnforcementResidents() {
      if (this.isLoading) {
        return false;
      }

      let url = `batch-notifications/${this.id}/house-users/enforcements`;

      if (!this.originalData.for_each_house_user) {
        url = `batch-notifications/${this.id}/residents/enforcements`;
      }

      this.isLoading = true;

      this.$api
        .post(url)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleSendConfirmed() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`batch-notifications/${this.id}/send`)
        .then((response) => {
          this.$toast.success(
            `${response.data.data.sent} adet bildirimin gönderimi başladı`
          );
          setTimeout(this.load, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.confirmSend.hide();
        });
    },
    handleClickCustomSelect() {
      if (this.originalData.for_each_house_user) {
        this.$refs.modalHouseUser.show();
      } else {
        this.$refs.modalResident.show();
      }
    },
    handleClickFilterSelect() {
      if (this.originalData.for_each_house_user) {
        this.$refs.addHouseUsersByFilterForm.show();
      } else {
        this.$refs.addResidentsByFilterForm.show();
      }
    },
    handleResidentSelected(idList) {
      this.isLoading = true;

      const formData = {
        resident_ids: idList,
      };

      this.$api
        .post(`batch-notifications/${this.id}/residents`, formData)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleHouseUserSelected(idList) {
      this.isLoading = true;

      const formData = {
        house_user_ids: idList,
      };

      this.$api
        .post(`batch-notifications/${this.id}/house-users`, formData)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.loadRecipientList, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleAddClick() {
      if (this.originalData.for_each_house_user) {
        this.$refs.batchNotificationHouseUserForm.show(this.id);
      } else {
        this.$refs.batchNotificationResidentForm.show(this.id);
      }
    },
    handleFormSaved(event) {
      this.isLoading = true;

      const data = {
        document_id: event.id,
        batch_notification_id: this.id,
      };

      this.$api
        .post(`batch-notifications/${this.id}/attachments`, data)
        .then(() => {
          this.$toast.success("Eklendi");
          setTimeout(this.load, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleAttachmentDeleteClick(item) {
      this.isLoading = true;

      this.$api
        .delete(`batch-notifications/${this.id}/attachments/${item.id}`)
        .then(() => {
          this.$toast.success("Silindi");
          setTimeout(this.load, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
pre {
  white-space: break-spaces;
}
</style>
