<template>
  <rs-side-panel ref="sidePanel" :size="size">
    <template slot="header">BB-Sakin Filtreleyin</template>
    <template slot="body">
      <p>
        Seçiminize uygun <strong>{{ total }}</strong> BB-sakin ikilisi
        bulunuyor.
      </p>

      <v-row dense>
        <v-col sm="6">
          <rs-select-block
            multiple
            v-model="filters.block_ids"
            @change="loadCount()"
            clearable
          ></rs-select-block>
        </v-col>
        <v-col sm="6">
          <rs-select
            label="Oturum Şekli"
            v-model="filters.house_user_type_ids"
            multiple
            @change="loadCount()"
            :items="houseUserTypeList"
            clearable
          />
        </v-col>
        <v-col sm="6">
          <rs-select
            :label="$t('labels.house_type')"
            v-model="filters.house_type_ids"
            multiple
            @change="loadCount()"
            :items="houseTypes"
            clearable
          />
        </v-col>
        <v-col sm="6">
          <v-switch
            :label="$t('labels.is_active')"
            v-model="filters.is_active"
            @change="loadCount()"
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <rs-form-buttons
        hide-submit
        :is-loading="isLoading"
        @cancel="hide()"
        @submit="handleFormSubmit"
        :save-close-label="total + ' adet sakini ekle'"
        :disable-submit="selectedIds.length === 0"
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm, hasDataTable } from "@/view/mixins";

export default {
  name: "AddHouseUsersByFilterForm",
  mixins: [hasForm, hasDataTable],
  props: {
    size: {
      type: String,
      required: false,
      default: "small",
    },
  },
  computed: {
    ...mapGetters(["clusterId", "houseTypes", "houseUserTypeList"]),
  },
  mounted() {
    this.loadCount();
  },
  data() {
    return {
      selectedIds: [],
      filters: {
        block_ids: [],
        is_active: true,
        house_type_ids: [],
        house_user_type_ids: [],
      },
      total: 0,
    };
  },
  methods: {
    loadCount() {
      const params = this.buildSearchParams(this.filters);

      params.per_page = 2000;
      this.selectedIds = [];

      this.$api
        .query(`clusters/${this.clusterId}/house-users`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.selectedIds = response.data.data
              .filter((v) => v.phone !== null)
              .map((v) => v.id);
          }

          this.total = this.selectedIds.length;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleSelectionUpdate(newItems) {
      this.selectedIds = newItems.map((v) => v.id);
    },
    handleFormSubmit() {
      this.$emit("selected", this.selectedIds);
      this.hide();
    },
    show() {
      this.originalData = {};
      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
  },
};
</script>
